//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from "axios";
import moment from "moment";
import api from "../api.js";
export default {
  data: () => ({
    menuItems: [
      {
        icon: "mdi-hand-okay",
        title: "Veure finalitzades",
        action: "ShowCompletedTasks",
        ccolor: "green lighten-2",
      },
      {
        icon: "mdi-pause",
        title: "Recordar pausades",
        action: "ReminderPausedTasks",
        ccolor: "red lighten-2",
      },
      {
        icon: "mdi-exit-to-app",
        title: "LogOut",
        action: "logout",
        ccolor: "",
      },
    ],
    itemList: [],
    stateList: [
      {
        color: "blue lighten-2",
        icon: "mdi-account-hard-hat",
        state: "T",
        textState: "TREBALLANT",
      },
      {
        color: "green lighten-2",
        icon: "mdi-hand-okay",
        state: "C",
        textState: "COMPLETADA PER L'OPERARI",
      },
      {
        color: "red lighten-2",
        icon: "mdi-pause",
        state: "W",
        textState: "PAUSADA PER L'OPERARI",
      },
    ],
    showCompletedTasks: false,
    reminderPausedTasks: false,
    dialogDataForm: false,
    dialogDataModel: false,
    dialogDataRef: false,
    dialogDataValue: new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .substring(0, 10),
    selDate: "",
    rules: {
      length: (len) => (v) =>
        (v || "").length >= len || `Invalid character length, required ${len}`,
      required: (v) => !!v || "This field is required",
    },
  }),
  mounted() {
    if (
      this.$route.query.init == "true" ||
      sessionStorage.getItem("sessWorkCalendarList_SelDate") == ""
    ) {
      this.selDate = this.formatDate(Date.now());
    } else {
      this.selDate = sessionStorage.getItem("sessWorkCalendarList_SelDate");
    }
    sessionStorage.setItem("sessWorkCalendarList_SelDate", this.selDate);
    this.getItemList();
  },
  methods: {
    getViewName() {
      return "WebVue_" + this.$route.name;
    },
    goBack() {
      this.$router.push("/mainmenu");
    },
    goMenu(action) {
      switch (action) {
        case "logout":
          this.goLogOut();
          break;
        case "ShowCompletedTasks":
          this.SwitchStorageValue(action);
          break;
        case "ReminderPausedTasks":
          this.SwitchStorageValue(action);
          break;
        default:
      }
    },
    dataAdd(q) {
      var d = new Date(this.selDate);
      d.setDate(d.getDate() + q);
      this.selDate = this.formatDate(d);
      sessionStorage.setItem("sessWorkCalendarList_SelDate", this.selDate);
      this.getItemList();
    },
    formatDate(value) {
      return moment(value).format("YYYY-MM-DD");
    },
    SwitchStorageValue(keyName) {
      axios
        .post(api.URL() + "/api/v1/ustoragevalues_switchboolvalue", {
          token: sessionStorage.getItem("Token"),
          userId: parseInt(sessionStorage.getItem("UserId")),
          section: this.getViewName(),
          key: keyName,
        })
        .then(() => {
          this.getItemList();
        })
        .catch(function (error) {
          this.msgAlert(error.response.message, true);
        });
    },
    getItemList() {
      axios
        .post(api.URL() + "/api/v1/gagendaoperaris_getitemsbyoperator", {
          token: sessionStorage.getItem("Token"),
          userId: parseInt(sessionStorage.getItem("UserId")),
          section: this.getViewName(),
          condition: "",
          order: "",
          date: new Date(this.selDate),
        })
        .then((response) => {
          if (response.error == null) {
            this.showCompletedTasks = response.data.showCompletedTasks;
            this.reminderPausedTasks = response.data.reminderPausedTasks;
            this.itemList = response.data.itemList;
          } else {
            this.msgAlert(response.error, true);
          }
        })
        .catch(function (error) {
          this.msgAlert(error.response.message, true);
        });
    },
    getStyle(state) {
      if (state == "C") {
        return "mdi-hand-okay";
      }
      if (state == "W") {
        return "mdi-pause";
      }
      return "mdi-account-hard-hat";
    },
    getColor(state, workingDocLineId) {
      if (state == "C") {
        return "green lighten-2";
      }
      if (state == "W") {
        return "red lighten-2";
      }
      if (workingDocLineId == 0) {
        return "orange lighten-2"; //No hi ha cap linia de feina oberta d'aquest usuari
      }
      return "blue lighten-2";
    },
    getVeureCheckedStr(value) {
      if (value == "ShowCompletedTasks") {
        if (this.showCompletedTasks) {
          return "✓ ";
        }
      }
      if (value == "ReminderPausedTasks") {
        if (this.reminderPausedTasks) {
          return "✓ ";
        }
      }
      return "";
    },
    goEdit(
      docType,
      docCompanyId,
      docYear,
      docSerialId,
      docId,
      docLineId,
      docSublineId,
      docCount
    ) {
      sessionStorage.setItem("sessDocType", docType);
      sessionStorage.setItem("sessDocCompanyId", docCompanyId);
      sessionStorage.setItem("sessDocYear", docYear);
      sessionStorage.setItem("sessDocSerialId", docSerialId);
      sessionStorage.setItem("sessDocId", docId);
      sessionStorage.setItem("sessDocLineId", docLineId);
      sessionStorage.setItem("sessDocSublineId", docSublineId);
      sessionStorage.setItem("sessDocCount", docCount);
      this.$router.push("/workcalendaredit");
    },
    goLogOut() {
      sessionStorage.setItem("Token", "");
      this.$router.push("/");
    },
    msgAlert(missatge, close) {
      var onClose = () => {
        if (close && close == true) {
          this.$router.push("/");
        }
      };

      this.$alert.show({ message: missatge, onClose: onClose });
    },
  },
};
