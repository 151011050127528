import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('p',{staticClass:"text-subtitle-2"},[_vm._v(" XFACT - SAT - SUBMINISTRAMENTS INDUSTRIALS LLORINA ")]),_c(VCard,[_c(VToolbar,{attrs:{"color":"blue lighten-2","cards":"","dark":"","flat":""}},[_c(VBtn,{attrs:{"color":"blue lighten-2"},on:{"click":function($event){return _vm.goBack()}}},[_c(VIcon,[_vm._v("mdi-arrow-left")])],1),_c(VSpacer),_vm._v(" TASQUES "),_c(VSpacer),_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"blue lighten-2","dark":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-menu")])],1)]}}])},[_c(VList,_vm._l((_vm.menuItems),function(item,index){return _c(VListItem,{key:index},[_c(VListItemIcon,[_c(VIcon,{attrs:{"color":item.ccolor},on:{"click":function($event){return _vm.goMenu(item.action)}}},[_vm._v(_vm._s(item.icon))])],1),_c(VListItemTitle,{on:{"click":function($event){return _vm.goMenu(item.action)}}},[_vm._v(_vm._s(_vm.getVeureCheckedStr(item.action) + item.title))])],1)}),1)],1)],1),_c(VForm,{ref:"dialogDataForm",staticClass:"pa-4 pt-6",model:{value:(_vm.dialogDataForm),callback:function ($$v) {_vm.dialogDataForm=$$v},expression:"dialogDataForm"}},[_c(VDialog,{ref:"dialogDataRef",attrs:{"return-value":_vm.dialogDataValue,"persistent":"","width":"300px"},on:{"update:returnValue":function($event){_vm.dialogDataValue=$event},"update:return-value":function($event){_vm.dialogDataValue=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,[_c(VTextField,_vm._g(_vm._b({attrs:{"rules":[_vm.rules.length(1)],"label":"Date","prepend-icon":"mdi-calendar","readonly":""},on:{"change":function($event){return _vm.getItemList()}},model:{value:(_vm.selDate),callback:function ($$v) {_vm.selDate=$$v},expression:"selDate"}},'v-text-field',attrs,false),on))],1),_c(VCol,[_c(VBtn,{staticClass:"ma-2",on:{"click":function($event){return _vm.dataAdd(1)}}},[_c(VIcon,[_vm._v("mdi-plus-circle-outline")])],1),_c(VBtn,{staticClass:"ma-2",on:{"click":function($event){return _vm.dataAdd(-1)}}},[_c(VIcon,[_vm._v("mdi-minus-circle-outline")])],1)],1)],1)]}}]),model:{value:(_vm.dialogDataModel),callback:function ($$v) {_vm.dialogDataModel=$$v},expression:"dialogDataModel"}},[_c(VDatePicker,{attrs:{"locale":"es"},model:{value:(_vm.selDate),callback:function ($$v) {_vm.selDate=$$v},expression:"selDate"}},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"blue lighten-2"},on:{"click":function($event){_vm.dialogDataModel = false}}},[_vm._v(" Cancel ")]),_c(VBtn,{attrs:{"text":"","color":"blue lighten-2"},on:{"click":function($event){_vm.$refs.dialogDataRef.save(_vm.dialogDataValue);
          _vm.getItemList();}}},[_vm._v(" OK ")])],1)],1)],1),_vm._l((_vm.itemList),function(item){return _c(VList,{key:item.docCompanyId +
      '-' +
      item.docYear +
      '-' +
      item.docSerialId +
      '-' +
      item.docId +
      '-' +
      item.docCount,attrs:{"three-line":"","dense":""}},[_c(VListItem,{attrs:{"stylex":"background-color: blueviolet;","color":"blue lighten-2"},on:{"click":function($event){return _vm.goEdit(
          item.docType,
          item.docCompanyId,
          item.docYear,
          item.docSerialId,
          item.docId,
          item.docLineId,
          item.docSublineId,
          item.docCount
        )}}},[_c(VListItemAvatar,[_c(VIcon,{attrs:{"large":"","color":_vm.getColor(item.orderState, item.workingDocLineId)}},[_vm._v(_vm._s(_vm.getStyle(item.orderState)))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.customerName))]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(item.strDOCId)+" "),(item.taskReference != '')?_c('h5',[_vm._v(" (Referència: "+_vm._s(item.taskReference)+") ")]):_vm._e(),_c('br'),_vm._v(" "+_vm._s(item.startDate)+" "+_vm._s(item.startTime.substr(0, 5))+" ")]),(item.observations != '')?_c(VListItemSubtitle,[_c('h5',{staticStyle:{"color":"Blue"}},[_vm._v(_vm._s(item.observations))])]):_vm._e(),(item.orderState == 'W')?_c(VListItemSubtitle,[_c('h5',{staticStyle:{"color":"red"}},[_vm._v(" Pausada "+_vm._s(item.orderWaitingReason)+" ")])]):_vm._e()],1)],1)],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }